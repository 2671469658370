import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_TNVENTORY_REQUEST_DETAILS = gql`
  query getInventoryRequestDetails($id: String, $externalId: String, $searchText: String) {
    getInventoryRequestDetails(id: $id, externalId: $externalId, searchText: $searchText) {
      caseId
      caseExternalId
      salesRepId
      salesRep
      eventType
      eventId
      division
      eventStatus
      serviceLevel
      serviceLevelJustification
      shipMethod
      orderType
      needByDate
      parentEventId
      parentEventType
      parentEventStatus
      parentEventDescription
      parentEventSubType
      parentEventStartDate
      parentCaseExternalId
      parentCaseId
      parentOrderid
      branchId
      branch
      accountId
      accountNumber
      accountName
      createdDate
      updatedDate
      updatedBy
      createdBy
      submittedDate
      shippingAddessLine1
      shippingAddessLine2
      shippingPostalcode
      shippingCity
      shippingState
      shippingId
      shippingAddressNumber
      cancelReason
      territoryNumber
      subInventoryCode
      totalCost
      isShippingDetails
      visualIndicator
      liftGateNeeded
      dockAvailable
      shortTruckOnly
      deliveryContractName
      deliveryContractPhone
      deliveryContractEmail
      deliveryContractInstructions
      thresholdQuantityIndicator
      cancelComment
      salesRepNameIns
      salesRepEmailIns
      salesRepPhoneIns
      shippingInstructions
      checkRejectLineStatus
      comments {
        id
        externalId
        value: comments
        type
        user
        postTime
      }
      lineItems {
        productOnHold
        productOnHoldType
        usageId
        usageExternalId
        productNo
        productId
        quantity
        actualQuantity
        status
        workflowAction
        productdescription
        cost
        lineVisualIndicator
        productFamily
        packContent
        salesUnitOfMeasure
        baseorCapital
        isAlreadyRequested
        lineThresholdQuantityIndicator
        thresholdQuantity
        isRejectedByBranchOps
        caseUsagesUpdatedByUser
      }
      attachments {
        id
        caseId
        caseExternalId
        recordType
        attachmentType
        showOnUsageSheet
        name
        createdBy
        createdAt
        imageurl
        user
      }
    }
  }
`;
