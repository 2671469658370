/* eslint-disable import/order */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable dot-notation */
/* eslint-disable no-console */
/* eslint-disable react/no-danger */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Icon from '@salesforce/design-system-react/components/icon';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import CancelModal from '../deleteNotificationsPopup';
import { GET_USER_NOTIFICATION_DETAILS } from '../../../graphql/getUserNotificationDetail';
import { UPDATE_USER_NOTIFICATION } from '../../../graphql/mutations/updateUserNotification';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import { getSvgIcon } from '../../../util/utilityFunctions';
import { getUserInfo } from '../../../store/ducks/userInfo';
import './index.scss';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  withStyles,
  makeStyles,
} from '@material-ui/core';
import EventStatusBadge from '../../Shared/EventStatusBadge';

const StyledDataTableRow = withStyles(() => ({
  root: {
    height: 35,
    background: 'white',
  },
}))(TableRow);

const StyledTableRow = withStyles(() => ({
  root: {
    height: 35,
    backgroundColor: '#f4f4f4',
    color: '#000000',
    fontFamily: '"SF Pro Text", sans-serif',
  },
}))(TableRow);

const StyledTableHeaderCell = withStyles(() => ({
  root: {
    fontWeight: 'bold',
    padding: '0px 5px',
    background: '#fafafa',
    textAlign: 'center',
    fontFamily: '"SF Pro Text", sans-serif',
  },
}))(TableCell);

const StyledCollapsibleTableCell = withStyles(() => ({
  root: {
    padding: '0px 0px',
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: '0px 5px',
    lineHeight: 'normal',
    background: 'white',
    fontFamily: '"SF Pro Text", sans-serif',
  },
}))(TableCell);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const getUoM = (name: string, packContent: string): string => {
  let actualUOM = name;
  if (packContent && Number(packContent) > 1) {
    actualUOM += `/ ${packContent}`;
  }
  return actualUOM;
};

// eslint-disable-next-line no-empty-pattern
const NotificationDetails: FC<RouteComponentProps> = ({ location, history }) => {
  const classes = useRowStyles();
  const { state } = location;
  const { id } = state;
  const { open, notification, openSnackBar } = useSnackBar();
  const userInfo = useSelector(getUserInfo);
  const [notificationDetails, setNotificationDetails] = useState<any>();
  const [updateNotification, { loading: isLoading }] = useMutation(UPDATE_USER_NOTIFICATION);
  const [
    getUserNotificationDetail,
    {
      data: getUserNotificationDetailData,
      refetch: refetchNotificationDetails,
      loading: getNotificationDetailsLoader,
    },
  ] = useLazyQuery(GET_USER_NOTIFICATION_DETAILS, {
    fetchPolicy: 'no-cache',
  });
  const starMarkedIcon = getSvgIcon('starMarked');
  const starNotMarkedIcon = getSvgIcon('starNotMarked');
  const [sortedFlag, setSortedFlag] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [toogle, setToogle] = useState(true);

  useEffect(() => {
    getUserNotificationDetail({ variables: { id } });
  }, [getUserNotificationDetail]);

  useEffect(() => {
    if (getUserNotificationDetailData && getUserNotificationDetailData.getUserNotificationDetail) {
      const data = getUserNotificationDetailData.getUserNotificationDetail;
      setNotificationDetails(data);
    }
  }, [getUserNotificationDetailData, refetchNotificationDetails]);

  const handleNavigateToEventList = (): void => {
    window.history.back();
  };

  const handleUpdateNotifications = (updateDetails: any, reloadFlag: boolean): void => {
    updateNotification({
      variables: updateDetails,
    }).then(response => {
      if (
        response &&
        response?.data &&
        response?.data?.updateUserNotification &&
        response?.data?.updateUserNotification?.message &&
        response?.data?.updateUserNotification?.message === 'success'
      ) {
        if (reloadFlag) {
          refetchNotificationDetails();
        } else {
          history.replace('/notificationCenter');
        }
      } else {
        console.log('error while update');
      }
    });
  };

  const toggleFlag = (): void => {
    const updateDetails = {
      id: [notificationDetails.id],
      type: 'Update',
      isStar: !notificationDetails.isStar,
    };
    handleUpdateNotifications(updateDetails, true);
  };

  const handleCancelModalConfirmPress = (): void => {
    const updateDetails = {
      id: [notificationDetails.id],
      type: 'Delete',
    };
    handleUpdateNotifications(updateDetails, false);
    setShowCancelPopup(!showCancelPopup);
  };

  const handleCancelModal = (): void => {
    setShowCancelPopup(!showCancelPopup);
  };

  const handleDelete = (): void => {
    setShowCancelPopup(true);
  };

  const handleMarkAsUnread = (): void => {
    const updateDetails = {
      id: [notificationDetails.id],
      type: 'Update',
      isRead: false,
    };
    handleUpdateNotifications(updateDetails, false);
  };

  const handleLink = () => {
    if (notificationDetails.screenName === 'Inventory Request') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/inventoryRequestDetails', {
        isEventInventoryFlow: false,
        externalId: transactionExternalId,
        type: 'Open',
      });
    }
    // redirect route for Event
    if (notificationDetails.screenName === 'Event') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/surgicalDetails', {
        isEventInventoryFlow: false,
        externalId: transactionExternalId,
        type: 'Open',
      });
    }
    // redirect route for Return Label
    if (notificationDetails.screenName === 'Return Label') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/inventoryReturnDetails', {
        externalId: transactionExternalId,
      });
    }
    // redirect route for SAP Return Rejection
    if (notificationDetails.screenName === 'SAP Return Rejection') {
      const { transactionExternalId, transactionSfid } = notificationDetails;
      history.push('/inventoryReturnDetails', {
        externalId: transactionExternalId,
        orderId: transactionSfid,
        type: 'Completed',
      });
    }
    // redirect route for Orders
    if (notificationDetails.screenName === 'Orders') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/OrdersUsageDetails', { externalId: transactionExternalId });
    }
    // redirect route for Inventory
    if (notificationDetails.screenName === 'Inventory') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/surgicalDetails', {
        externalId: transactionExternalId,
        id,
      });
    }
    // redirect route for Sub Inventory List
    if (notificationDetails.screenName === 'Sub Inventory List') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/inventory', {
        externalId: transactionExternalId,
        id,
      });
    }
    // redirect route for Inventory Request-Order
    if (notificationDetails.screenName === 'Inventory Request-Order') {
      const { transactionSfid } = notificationDetails;
      history.push('/inventoryRequestDetails', {
        isEventInventoryFlow: false,
        externalId: transactionSfid,
        type: 'Failed',
      });
    }
    // redirect route for Bill Only-Order
    if (notificationDetails.screenName === 'Bill Only-Order') {
      const { transactionSfid, transactionType } = notificationDetails;
      history.push('/OrdersUsageDetails', { orderId: transactionSfid, type: 'Failed' });
    }
    // redirect route for SAP Rejection, IR Shippment, IR Submission
    if (
      notificationDetails.screenName === 'SAP Rejection' ||
      notificationDetails.screenName === 'IR Shippment' ||
      notificationDetails.screenName === 'IR Submission'
    ) {
      const { transactionSfid } = notificationDetails;
      history.push('/inventoryRequestDetails', {
        isEventInventoryFlow: false,
        externalId: transactionSfid,
        type: 'Processed',
      });
    }
    // redirect route for S&B Shippment
    if (notificationDetails.screenName === 'S&B Shippment') {
      const { transactionSfid } = notificationDetails;
      history.push('/OrdersUsageDetails', {
        orderId: transactionSfid,
        type: 'Processed',
      });
    }
    // redirect route for Cycle Count
    if (notificationDetails?.screenName === 'Cycle Count') {
      const { transactionSfid } = notificationDetails;
      const cycleCountId = transactionSfid
        ?.replace(/\\/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/"/g, '');
      history.push('/cycleCountDetails', {
        cycleCountExternalId: cycleCountId,
      });
    }
  };

  const actions = (): ReactNode => (
    <PageHeaderControl>
      <div style={{ display: 'flex' }}>
        <Button
          style={{ color: 'black', marginRight: '10px', fontWeight: 600, paddingVertical: '10px' }}
          align="right"
          label="Mark as Unread"
          onClick={handleMarkAsUnread}
        />
        <div style={{ float: 'right' }} onClick={handleDelete}>
          <Icon
            style={{ margin: '10px', float: 'right' }}
            category="utility"
            name="delete"
            size="small"
          />
        </div>
      </div>
    </PageHeaderControl>
  );

  let detailsString = notificationDetails
    ? notificationDetails?.details?.replaceAll('<br />', `\n`)
    : '';
  const detailsText = notificationDetails
    ? notificationDetails?.statmentValue?.replaceAll('<br />', `\n`)
    : '';
  const splitted =
    notificationDetails && notificationDetails?.description
      ? notificationDetails?.description?.split('____', 2)
      : '';
  if (splitted) {
    detailsString = detailsString ? detailsString.replaceAll('CCStartDateValue', splitted[0]) : '';
    detailsString = detailsString ? detailsString.replaceAll('CCEndDateValue', splitted[1]) : '';
  }

  const hyperlink = `<a href=${notificationDetails?.ccLink || ''} target="_blank">instructions</a>`;
  const inprogress = getSvgIcon('inprogress');

  const tableDetails = notificationDetails?.tableDetails?.map((item: string) => JSON.parse(item));

  const handleImpact = (e: any) => {
    const eventId = e.target.textContent;
    const IRImpactId = tableDetails[0]?.eventInventory.filter(
      (event: { eventId: any }) => event.eventId === eventId
    );
    const transferImpactId = tableDetails[0]?.eventTransfer.filter(
      (event: { eventId: any }) => event.eventId === eventId
    );
    const orderImpactId = tableDetails[0]?.eventOrder.filter(
      (event: { eventId: any }) => event.eventId === eventId
    );
    const returnImpactId = tableDetails[0]?.eventReturn.filter(
      (event: { eventId: any }) => event.eventId === eventId
    );

    if (IRImpactId.length > 0) {
      const { externalId } = IRImpactId[0];
      history.push('/inventoryRequestDetails', {
        isEventInventoryFlow: false,
        externalId,
        type: 'Open',
      });
    }
    if (transferImpactId.length > 0) {
      const { externalId } = transferImpactId[0];
      history.push('/inventoryTransferDetails', {
        isEventInventoryFlow: false,
        externalId,
        type: 'Open',
      });
    }
    if (orderImpactId.length > 0) {
      const { externalId } = orderImpactId[0];
      history.push('/OrdersUsageDetails', {
        externalId,
        type: 'Processed',
      });
    }
    if (returnImpactId.length > 0) {
      const { externalId } = returnImpactId[0];
      history.push('/inventoryReturnDetails', {
        externalId,
      });
    }
  };

  /**
   * method to get formatted date for UI view
   * @returns paragraph with formatted dates
   */
  const getFormattedDates = () => {
    return tableDetails?.map((item: any) => {
      return (
        <p key={`${item.startDate}-${item.endDate}`}>
          {`${new Date(item.startDate).toLocaleDateString()} - ${new Date(
            item.endDate
          ).toLocaleDateString()}`}
        </p>
      );
    });
  };

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {(getNotificationDetailsLoader || isLoading) && (
        <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
      )}
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={handleNavigateToEventList}
        />
      </div>
      <PageHeader
        className="slds-m-around_small slds-m-bottom_none"
        icon={
          <img
            src="icons/notification.svg"
            className="logo-image"
            alt="company"
            style={{ width: '2.5rem', height: '2.5rem' }}
          />
        }
        onRenderActions={actions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">Notification</span>
          </div>
        }
        variant="record-home"
      />
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-wrap slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        <li className="slds-page-header__detail  slds-size_1-of-12">
          <div
            className="slds-text-title"
            title="Flag"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Flag
          </div>
          <div className="slds-tooltip-trigger">
            <p onClick={toggleFlag} style={{ padding: '5px' }}>
              {notificationDetails && notificationDetails.isStar ? (
                <img src={starMarkedIcon} className="logo-image" alt="ascending" />
              ) : (
                <img src={starNotMarkedIcon} className="logo-image" alt="descending" />
              )}
            </p>
          </div>
        </li>
        <li className=" slds-page-header__detail slds-size_2-of-12">
          <div
            className="slds-text-title"
            title="Notification ID"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Notification ID
          </div>
          <div className="slds-tooltip-trigger" style={{ fontSize: '16px' }}>
            <div title={notificationDetails?.id || ''}>{notificationDetails?.id || ''}</div>
          </div>
        </li>
        {notificationDetails?.screenName !== 'Sub Inventory List' && (
          <li className=" slds-page-header__detail slds-size_2-of-12">
            <div
              className="slds-text-title"
              title="Notification ID"
              style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
            >
              Case ID
            </div>
            <div className="slds-tooltip-trigger" style={{ fontSize: '16px' }}>
              <div title={notificationDetails?.transactionNumber || ''}>
                {notificationDetails?.transactionNumber || ''}
              </div>
            </div>
          </li>
        )}
        <li className="slds-page-header__detail  slds-size_2-of-12">
          <div
            className="slds-text-title"
            title="User"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            User
          </div>
          <div className="slds-tooltip-trigger" style={{ fontSize: '16px' }}>
            <div title={notificationDetails?.salesRepName || ''}>
              {(notificationDetails?.subject === 'You have now been removed from Trial Hold' &&
                notificationDetails?.transactionSfid === '') ||
              (notificationDetails?.subject === 'You have now been placed on Trial Hold' &&
                notificationDetails?.transactionSfid === '')
                ? notificationDetails?.createdBy || ''
                : notificationDetails?.salesRepName || ''}
            </div>
          </div>
        </li>
        {notificationDetails?.screenName !== 'Sub Inventory List' && (
          <li className="slds-page-header__detail  slds-size_3-of-12">
            <div
              className="slds-text-title"
              title="User"
              style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
            >
              Account
            </div>
            <div className="slds-tooltip-trigger" style={{ fontSize: '16px' }}>
              {notificationDetails?.account || ' '}
            </div>
          </li>
        )}
        <li className="slds-page-header__detail slds-size_2-of-12">
          <div
            className="slds-text-title"
            title="Type"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Type
          </div>
          <div className="slds-tooltip-trigger">
            <div style={{ fontSize: '16px' }} title={notificationDetails?.transactionType || ''}>
              {notificationDetails?.transactionType || ''}
            </div>
          </div>
        </li>
      </ul>
      <ul
        className="slds-page-header__detail-row slds-grid slds-gutters slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        <li className="slds-page-header__detail slds-size_1-of-12">
          <div
            className="slds-text-title"
            title="Link"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Link
          </div>
          <div className="slds-tooltip-trigger">
            {notificationDetails?.screenName !== 'Sub Inventory List' &&
            notificationDetails?.subject !== 'You have now been placed on Trial Hold' &&
            notificationDetails?.subject !== 'You have now been removed from Trial Hold' ? (
              <a onClick={handleLink}>{notificationDetails?.transactionNumber || '--'}</a>
            ) : (
              '--'
            )}
          </div>
        </li>
        {notificationDetails?.screenName !== 'Cycle Count' ? (
          <li className="slds-page-header__detail slds-size_2-of-12">
            <div
              className="slds-text-title"
              title="Date"
              style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
            >
              Date
            </div>
            <p className="slds-page-header__detail slds-line-clamp" style={{ fontSize: '16px' }}>
              {notificationDetails?.notificationCreatedDate
                ? moment(notificationDetails?.notificationCreatedDate)
                    .utc()
                    .format('MM/DD/YYYY')
                : ' '}
            </p>
          </li>
        ) : (
          ''
        )}
        {notificationDetails?.screenName === 'Event' &&
          !notificationDetails?.subject.includes('Trial Hold') && (
            <li className="slds-page-header__detail slds-size_3-of-12">
              <div
                className="slds-text-title"
                title="Date"
                style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
              >
                Sales Rep
              </div>
              <p className="slds-page-header__detail slds-line-clamp" style={{ fontSize: '16px' }}>
                {notificationDetails?.salesRep ? notificationDetails?.salesRep : ' '}
              </p>
            </li>
          )}
        {notificationDetails?.screenName === 'Cycle Count' && (
          <li className="slds-page-header__detail slds-size_2-of-12">
            <div
              className="slds-text-title"
              title="Date"
              style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
            >
              Counters
            </div>
            <p className="slds-page-header__detail" style={{ fontSize: '16px' }}>
              {tableDetails?.[0]?.counters?.map((rep: { salesRepName: any }) => {
                return (
                  <p>
                    {rep.salesRepName}{' '}
                    <img
                      className="slds-tooltip-trigger"
                      src={inprogress}
                      title="In Progress"
                      alt="In Progress"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  </p>
                );
              })}
            </p>
          </li>
        )}
        <>
          {!notificationDetails?.oldDate &&
            (notificationDetails?.subject.includes('Event Changed') ||
              notificationDetails?.subject.includes('Cycle count')) && (
              <li className="slds-page-header__detail slds-size_4-of-12">
                <div
                  className="slds-text-title"
                  title="Date"
                  style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
                >
                  Start Date - End Date
                </div>
                <p
                  className="slds-page-header__detail slds-line-clamp"
                  style={{ fontSize: '16px' }}
                >
                  {getFormattedDates() || ''}
                </p>
              </li>
            )}
          {notificationDetails?.oldDate && (
            <>
              <li
                className="slds-page-header__detail slds-size_4-of-12"
                style={{ marginBottom: '10px' }}
              >
                <div
                  className="slds-text-title"
                  title="Date"
                  style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
                >
                  Original Start Date - Original End Date
                </div>
                <p
                  className="slds-page-header__detail slds-line-clamp"
                  style={{ fontSize: '16px' }}
                >
                  {notificationDetails?.oldDate || ''}
                </p>
                <div
                  className="slds-text-title"
                  title="Date"
                  style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600, marginTop: '10px' }}
                >
                  Changed Start Date - Changed End Date
                </div>
                <p
                  className="slds-page-header__detail slds-line-clamp"
                  style={{ fontSize: '16px' }}
                >
                  {getFormattedDates() || ''}
                </p>
              </li>
            </>
          )}
        </>
        <li className="slds-page-header__detail slds-size_4-of-12">
          <div
            className="slds-text-title"
            title="Subject"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Subject
          </div>
          <div className="slds-tooltip-trigger">
            <div style={{ fontSize: '16px' }} title={notificationDetails?.subject || ''}>
              {notificationDetails?.subject || ''}
            </div>
          </div>
        </li>
        {notificationDetails?.screenName === 'Inventory' &&
          notificationDetails?.subject.includes('days') &&
          tableDetails && (
            <li className="slds-page-header__detail slds-size_4-of-12">
              <div
                className="slds-text-title"
                title="Subject"
                style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
              >
                Territory
              </div>
              <div className="slds-tooltip-trigger">
                <div style={{ fontSize: '16px' }} title={notificationDetails?.subject || ''}>
                  {notificationDetails?.territory || ''}
                </div>
              </div>
            </li>
          )}
        {(notificationDetails?.screenName === 'Inventory Request-Order' ||
          notificationDetails?.screenName === 'Bill Only-Order') && (
          <li className="slds-page-header__detail slds-size_4-of-12">
            <div
              className="slds-text-title"
              title="Subject"
              style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
            >
              Failure Reason
            </div>
            <div className="slds-tooltip-trigger">
              <div style={{ fontSize: '16px' }} title={notificationDetails?.subject || ''}>
                {notificationDetails?.statmentValue || ''}
              </div>
            </div>
          </li>
        )}
        {notificationDetails?.screenName === 'Events' && (
          <li className="slds-page-header__detail slds-size_4-of-12">
            <div
              className="slds-text-title"
              title="Subject"
              style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
            >
              Reason
            </div>
            <div className="slds-tooltip-trigger">
              <div style={{ fontSize: '16px' }} title={notificationDetails?.subject || ''}>
                {tableDetails?.[0]?.reasonStatement || ''}
              </div>
            </div>
          </li>
        )}
      </ul>
      <ul
        className="slds-page-header__detail-row slds-grid slds-gutters slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        <li
          className={
            notificationDetails?.screenName === 'Event'
              ? 'slds-page-header__detail slds-size_10-of-12'
              : 'slds-page-header__detail slds-size_12-of-12'
          }
        >
          <div
            className="slds-text-title"
            title="Details"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Details
          </div>
          <div
            style={{ fontSize: '16px', whiteSpace: 'pre-wrap' }}
            className="slds-tooltip-trigger"
          >
            {notificationDetails?.subject === 'You have now been removed from Trial Hold' ||
            notificationDetails?.subject === 'You have now been placed on Trial Hold' ? (
              <span>{notificationDetails?.details}</span>
            ) : (
              ''
            )}
            {notificationDetails?.screenName === 'Hold Inventory' ? (
              <span>{notificationDetails?.details}</span>
            ) : (
              ''
            )}
            {(notificationDetails?.screenName === 'IR Shippment' ||
              notificationDetails?.screenName === 'S&B Shippment') && (
              <span>
                {detailsText?.split(notificationDetails?.transactionNumber)[0]}
                <a onClick={handleLink}>{notificationDetails?.transactionNumber || ''}</a>
                <span
                  dangerouslySetInnerHTML={{
                    __html: detailsText?.split(notificationDetails?.transactionNumber)[1],
                  }}
                />
              </span>
            )}
            {notificationDetails?.screenName !== 'Sub Inventory List' &&
              notificationDetails?.screenName !== 'Hold Inventory' &&
              notificationDetails?.screenName !== 'IR Shippment' &&
              notificationDetails?.subject !== 'You have now been removed from Trial Hold' &&
              notificationDetails?.subject !== 'You have now been placed on Trial Hold' &&
              notificationDetails?.screenName !== 'S&B Shippment' && (
                <>
                  <span>
                    {detailsString.split(notificationDetails?.transactionNumber)[0]}
                    <a onClick={handleLink}>{notificationDetails?.transactionNumber || ''}</a>
                    {notificationDetails?.transactionType === 'Cycle Count' ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: detailsString
                            .split(notificationDetails?.transactionNumber)[1]
                            ?.replace('instructions', hyperlink),
                        }}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: detailsString.split(notificationDetails?.transactionNumber)[1],
                        }}
                      />
                    )}
                  </span>
                </>
              )}
            {notificationDetails?.screenName === 'Sub Inventory List' ? (
              <div className="App">
                <p style={{ paddingTop: '8px' }}>Hello,</p>
                <p style={{ paddingTop: '20px' }}>{notificationDetails?.statmentValue || ''}</p>
                <h1 style={{ padding: '8px', marginTop: '20px' }} className="table_header">
                  {notificationDetails?.tableHeader || ''}
                </h1>
                <table style={{ border: '1px solid black' }}>
                  <thead>
                    <tr>
                      <th
                        style={{ padding: '8px', border: '1px solid #dddddd', textAlign: 'center' }}
                      >
                        Part
                      </th>
                      <th
                        style={{ padding: '8px', border: '1px solid #dddddd', textAlign: 'center' }}
                      >
                        Lot/Serial Number
                      </th>
                      <th
                        style={{ padding: '8px', border: '1px solid #dddddd', textAlign: 'center' }}
                      >
                        Expiration Date
                      </th>
                      <th
                        style={{ padding: '8px', border: '1px solid #dddddd', textAlign: 'center' }}
                      >
                        Account
                      </th>
                      <th
                        style={{ padding: '8px', border: '1px solid #dddddd', textAlign: 'center' }}
                      >
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {notificationDetails &&
                      notificationDetails?.tableValue?.length > 0 &&
                      notificationDetails?.tableValue?.map((value: any, key: any) => {
                        return (
                          <tr>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {value.productNumber}
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {value.lotNumber}
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {moment(new Date(value.expirationDate))
                                .utc()
                                .local()
                                .format('MM/DD/YYYY')}
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {value.Account}
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {value.quantity ? value.quantity : '--'}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              ''
            )}
            {((notificationDetails?.screenName === 'Inventory' &&
              notificationDetails?.subject.includes('days')) ||
              notificationDetails?.subject.includes('Trial Hold')) &&
              tableDetails?.length && (
                <div className="App">
                  <table style={{ border: '1px solid black', marginTop: '20px' }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            padding: '8px',
                            border: '1px solid #dddddd',
                            textAlign: 'center',
                          }}
                        >
                          Part
                        </th>
                        <th
                          style={{
                            padding: '8px',
                            border: '1px solid #dddddd',
                            textAlign: 'center',
                          }}
                        >
                          Lot/Serial Number
                        </th>
                        <th
                          style={{
                            padding: '8px',
                            border: '1px solid #dddddd',
                            textAlign: 'center',
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          style={{
                            padding: '8px',
                            border: '1px solid #dddddd',
                            textAlign: 'center',
                          }}
                        >
                          Days At Event
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableDetails?.map((part: any, key: any) => {
                        let icon: any;
                        let color: any;
                        if (part.daysAtEvent < 60) {
                          color = '#58b837';
                        }
                        if (part.daysAtEvent >= 60) {
                          color = '#1b5687';
                        }
                        if (part.daysAtEvent >= 75) {
                          color = '#ffb503';
                        }
                        if (part.daysAtEvent >= 90) {
                          icon = 'warning';
                          color = '#b83c27';
                        }
                        let days = part.daysAtEvent;
                        if (Number(part.daysAtEvent) <= 0) {
                          days = 0;
                        }
                        return (
                          <tr>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {part.productNumber && part.productdescription
                                ? `${part.productNumber} - ${part.productdescription}`
                                : '--'}
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {part.lotNumber ? part.lotNumber : '--'}
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {part.quantity ? part.quantity : '--'}
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              <span
                                style={{
                                  color: 'white',
                                  backgroundColor: color,
                                  paddingRight: '10px',
                                  paddingLeft: '10px',
                                  paddingTop: '4px',
                                  paddingBottom: '4px',
                                  borderRadius: '15% / 50%',
                                }}
                              >
                                {days}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        </li>
      </ul>
      {notificationDetails?.screenName === 'Event' &&
        notificationDetails?.subject.includes('Event Changed') && (
          <ul
            className="slds-page-header__detail-row slds-grid slds-gutters slds-m-around_small slds-m-top_none slds-m-bottom_none"
            style={{ borderBottom: 'none' }}
          >
            <li className="slds-page-header__detail slds-size_2-of-12">
              <div
                className="slds-text-title"
                title="Subject"
                style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
              >
                Requests Impacted
              </div>
              <div className="slds-tooltip-trigger">
                {notificationDetails?.screenName === 'Event'
                  ? tableDetails?.map((inventory: { eventInventory: any }) =>
                      inventory.eventInventory.length > 0
                        ? inventory?.eventInventory.map((e: any) => (
                            <a
                              className="slds-page-header__detail slds-line-clamp"
                              style={{ fontSize: '16px' }}
                              onClick={handleImpact}
                            >
                              {e.eventId}
                            </a>
                          ))
                        : '--'
                    )
                  : '--'}
              </div>
            </li>
            <li className="slds-page-header__detail slds-size_2-of-12">
              <div
                className="slds-text-title"
                title="Subject"
                style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
              >
                Transfer Impacted
              </div>
              <div className="slds-tooltip-trigger">
                {notificationDetails?.screenName === 'Event'
                  ? tableDetails?.map((inventory: { eventTransfer: any }) =>
                      inventory.eventTransfer.length > 0
                        ? inventory?.eventTransfer.map((e: any) => (
                            <a
                              className="slds-page-header__detail slds-line-clamp"
                              style={{ fontSize: '16px' }}
                              onClick={handleImpact}
                            >
                              {e.eventId}
                            </a>
                          ))
                        : '--'
                    )
                  : '--'}
              </div>
            </li>
            <li className="slds-page-header__detail slds-size_2-of-12">
              <div
                className="slds-text-title"
                title="Subject"
                style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
              >
                Returns Impacted
              </div>
              <div className="slds-tooltip-trigger">
                {notificationDetails?.screenName === 'Event'
                  ? tableDetails?.map((inventory: { eventReturn: any }) =>
                      inventory.eventReturn.length > 0
                        ? inventory?.eventReturn.map((e: any) => (
                            <a
                              className="slds-page-header__detail slds-line-clamp"
                              style={{ fontSize: '16px' }}
                              onClick={handleImpact}
                            >
                              {e.eventId}
                            </a>
                          ))
                        : '--'
                    )
                  : '--'}
              </div>
            </li>
            <li className="slds-page-header__detail slds-size_2-of-12">
              <div
                className="slds-text-title"
                title="Subject"
                style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
              >
                Orders Impacted
              </div>
              <div className="slds-tooltip-trigger">
                {notificationDetails?.screenName === 'Event'
                  ? tableDetails?.map((inventory: { eventOrder: any }) =>
                      inventory.eventOrder.length > 0
                        ? inventory?.eventOrder.map((e: any) => (
                            <a
                              className="slds-page-header__detail slds-line-clamp"
                              style={{ fontSize: '16px' }}
                              onClick={handleImpact}
                            >
                              {e.eventId}
                            </a>
                          ))
                        : '--'
                    )
                  : '--'}
              </div>
            </li>
          </ul>
        )}
      {[
        'Inventory Return',
        'IR Shippment',
        'IR Submission',
        'Bill Only-Order',
        'Inventory Request-Order',
        'Inventory',
        'SAP Rejection',
      ].includes(notificationDetails?.screenName) &&
      !notificationDetails?.subject.includes('days') ? (
        <ul
          className="slds-page-header__detail-row slds-grid slds-col slds-m-around_small slds-m-top_none slds-m-bottom_none"
          style={{ borderBottom: 'none' }}
        >
          <div className="slds-page-header__detail  slds-size_12-of-12">
            <IconSettings iconPath="/icons">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <StyledTableRow>
                    {tableDetails?.length > 0 && (
                      <StyledTableHeaderCell style={{ width: '2%' }}>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setToogle(!toogle)}
                        >
                          {toogle ? (
                            <Icon
                              category="utility"
                              name="normal_on-all_u593"
                              size="small"
                              style={{
                                display: 'inline-block',
                                marginLeft: '10px',
                                marginTop: '10px',
                                fill: 'white',
                              }}
                            />
                          ) : (
                            <Icon
                              category="utility"
                              name="double_arrow_collapse"
                              size="small"
                              style={{
                                display: 'inline-block',
                                marginLeft: '10px',
                                marginTop: '10px',
                                fill: 'white',
                              }}
                            />
                          )}
                        </IconButton>
                      </StyledTableHeaderCell>
                    )}
                    <StyledTableHeaderCell style={{ width: '18%', textAlign: 'left' }}>
                      Part
                    </StyledTableHeaderCell>
                    {notificationDetails?.screenName.includes('Bill Only-Order') && (
                      <StyledTableHeaderCell style={{ width: '10%', textAlign: 'center' }}>
                        Lot/Serial
                      </StyledTableHeaderCell>
                    )}
                    <StyledTableHeaderCell
                      style={{ width: '10%', textAlign: 'left', paddingLeft: '32px' }}
                    >
                      Status
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '7%' }}>
                      Ordered Qty
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '8%' }}>
                      Actual UoM
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '8%' }}>
                      Actual Qty
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '10%' }}>
                      Backorder Qty
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '8%' }}>
                      Shipped Qty
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '7%' }}>ERP Order</StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '6%' }}>
                      Tracking #
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '6%' }}>Delivery</StyledTableHeaderCell>
                  </StyledTableRow>
                  <TableBody>
                    {tableDetails?.map((items: any) => (
                      <Body
                        toogle={toogle}
                        items={items}
                        notificationDetails={notificationDetails}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </IconSettings>
          </div>
        </ul>
      ) : (
        ''
      )}
      {notificationDetails?.screenName === 'Inventory Request' && (
        <ul
          className="slds-page-header__detail-row slds-grid slds-col slds-m-around_small slds-m-top_none slds-m-bottom_none"
          style={{ borderBottom: 'none' }}
        >
          <div className="slds-page-header__detail  slds-size_12-of-12">
            <IconSettings iconPath="/icons">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <StyledTableRow>
                    <StyledTableHeaderCell style={{ width: '18%', textAlign: 'left' }}>
                      Part
                    </StyledTableHeaderCell>
                    {notificationDetails?.screenName.includes('Bill Only-Order') && (
                      <StyledTableHeaderCell style={{ width: '10%', textAlign: 'center' }}>
                        Lot/Serial
                      </StyledTableHeaderCell>
                    )}
                    <StyledTableHeaderCell
                      style={{ width: '10%', textAlign: 'left', paddingLeft: '32px' }}
                    >
                      Status
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '7%' }}>
                      Ordered Qty
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '8%' }}>
                      Actual UoM
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '8%' }}>
                      Actual Qty
                    </StyledTableHeaderCell>
                  </StyledTableRow>
                  <TableBody>
                    {tableDetails?.map((items: any) => (
                      <StyledDataTableRow
                        className={classes.root}
                        style={{ backgroundColor: 'white' }}
                      >
                        <StyledTableCell style={{ width: '18%', textAlign: 'left' }}>
                          {`${items?.productcode} - ${items?.productdescription}`}
                          {items?.failureReason && (
                            <span style={{ marginLeft: '10px' }} title={items?.failureReason}>
                              <Icon
                                category="utility"
                                name="warning"
                                size="x-small"
                                style={{ fill: '#D2042D', marginRight: '5px' }}
                              />
                            </span>
                          )}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '10%', fontWeight: 'bold' }}>
                          <EventStatusBadge status={items?.partStatus} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '7%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {items?.quantity}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '8%' }}>
                          <div
                            style={{
                              paddingLeft: '5px',
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            {getUoM(items.salesUnitOfMeasure, items.packContent)}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '8%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {items.actualQuantity}
                        </StyledTableCell>
                      </StyledDataTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </IconSettings>
          </div>
        </ul>
      )}
      {notificationDetails?.screenName === 'SAP Return Rejection' && (
        <ul
          className="slds-page-header__detail-row slds-grid slds-col slds-m-around_small slds-m-top_none slds-m-bottom_none"
          style={{ borderBottom: 'none' }}
        >
          <div className="slds-page-header__detail  slds-size_12-of-12">
            <IconSettings iconPath="/icons">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <StyledTableRow>
                    <StyledTableHeaderCell style={{ width: '18%', textAlign: 'left' }}>
                      Part
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '10%', textAlign: 'center' }}>
                      Lot/Serial
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '10%', textAlign: 'center' }}>
                      Expiration Date
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      style={{ width: '10%', textAlign: 'left', paddingLeft: '32px' }}
                    >
                      Status
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '10%', textAlign: 'center' }}>
                      Tracking #
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '10%', textAlign: 'center' }}>
                      Returning Qty
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '10%', textAlign: 'center' }}>
                      Recieving Qty
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '10%', textAlign: 'center' }}>
                      ERP Order
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '10%', textAlign: 'center' }}>
                      Recieved
                    </StyledTableHeaderCell>
                  </StyledTableRow>
                  <TableBody>
                    {tableDetails?.map((items: any) => (
                      <StyledDataTableRow
                        className={classes.root}
                        style={{ backgroundColor: 'white' }}
                      >
                        <StyledTableCell style={{ width: '18%', textAlign: 'left' }}>
                          {`${items?.productcode} - ${items?.productdescription}`}
                          {items?.failureReason && (
                            <span style={{ marginLeft: '10px' }} title={items?.failureReason}>
                              <Icon
                                category="utility"
                                name="warning"
                                size="x-small"
                                style={{ fill: '#D2042D', marginRight: '5px' }}
                              />
                            </span>
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '10%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {items?.lotNumber}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '7%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {items.expirationDate
                            ? new Date(items.expirationDate).toLocaleDateString()
                            : '--'}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '7%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          <EventStatusBadge status={items?.lineVisualIndicator} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '8%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {items?.trackingNumber}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '8%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {items?.quantity}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '8%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {items?.receivedQuantity}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '8%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {items?.erpOrderNumber}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '8%', fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {items?.receivedDate}
                        </StyledTableCell>
                      </StyledDataTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </IconSettings>
          </div>
        </ul>
      )}
      {showCancelPopup && (
        <CancelModal
          canceModalVisible={showCancelPopup}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
        />
      )}
    </IconSettings>
  );
};

const Body = ({ toogle, items, notificationDetails }: any) => {
  const classes = useRowStyles();
  const iconBackorder = getSvgIcon('backorder');
  const [rowToogle, setRowToogle] = useState(true);
  useEffect(() => setRowToogle(toogle), [toogle]);

  return (
    <>
      <StyledDataTableRow className={classes.root} style={{ backgroundColor: 'white' }}>
        <StyledTableCell style={{ width: '2%', textAlign: 'center' }}>
          {items?.shippingLineItems?.length > 0 && (
            <span>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setRowToogle(!rowToogle)}
              >
                {!rowToogle ? (
                  <Icon
                    assistiveText={{ label: 'Announcement' }}
                    category="utility"
                    name="chevronright"
                    size="x-small"
                    style={{
                      fill: '#000',
                      display: 'inline-block',
                    }}
                  />
                ) : (
                  <Icon
                    assistiveText={{ label: 'Announcement' }}
                    category="utility"
                    name="chevrondown"
                    size="x-small"
                    style={{
                      fill: '#000',
                      display: 'inline-block',
                    }}
                  />
                )}
              </IconButton>
            </span>
          )}
        </StyledTableCell>
        <StyledTableCell style={{ width: '18%', textAlign: 'left' }}>
          {`${items?.productcode} - ${items?.productdescription}`}
          {items?.failureReason && (
            <span style={{ marginLeft: '10px' }} title={items?.failureReason}>
              <Icon
                category="utility"
                name="warning"
                size="x-small"
                style={{ fill: '#D2042D', marginRight: '5px' }}
              />
            </span>
          )}
        </StyledTableCell>
        {notificationDetails?.screenName.includes('Bill Only-Order') && (
          <StyledTableCell style={{ width: '10%', fontWeight: 'bold', textAlign: 'center' }}>
            {items?.lotNumber}
          </StyledTableCell>
        )}
        <StyledTableCell
          style={{
            width: '10%',
            fontWeight: 'bold',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
            }}
          >
            <EventStatusBadge status={items?.partStatus} />
            {Number(items?.backorderQuantity) > 0 && (
              <img
                src={iconBackorder}
                alt="backorder"
                style={{ width: '20px', height: '15px', marginLeft: '5px' }}
              />
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell style={{ width: '7%', fontWeight: 'bold', textAlign: 'center' }}>
          {items?.quantity}
        </StyledTableCell>
        <StyledTableCell style={{ width: '8%' }}>
          <div
            style={{
              paddingLeft: '5px',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {getUoM(items.salesUnitOfMeasure, items.packContent)}
          </div>
        </StyledTableCell>
        <StyledTableCell style={{ width: '8%', fontWeight: 'bold', textAlign: 'center' }}>
          {items.actualQuantity}
        </StyledTableCell>
        <StyledTableCell style={{ width: '10%', fontWeight: 'bold', textAlign: 'center' }}>
          {items?.backorderQuantity === '0' ? '' : items?.backorderQuantity}
        </StyledTableCell>
        <StyledTableCell
          style={{
            width: '8%',
            fontWeight: 'bold',
          }}
        >
          {items?.deliveryQuantity === '0' ? '' : items?.deliveryQuantity}
        </StyledTableCell>
        <StyledTableCell style={{ width: '7%' }}>{}</StyledTableCell>
        <StyledTableCell style={{ width: '6%' }}>{}</StyledTableCell>
        <StyledTableCell style={{ width: '6%' }}>{}</StyledTableCell>
      </StyledDataTableRow>
      <TableRow>
        <StyledCollapsibleTableCell colSpan={12}>
          <Collapse in={rowToogle} timeout="auto" unmountOnExit>
            <Box>
              <Table style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                {items.shippingLineItems?.map((shipping: any) => {
                  return (
                    <StyledDataTableRow>
                      <StyledTableCell style={{ width: '2%' }}>{}</StyledTableCell>
                      <StyledTableCell style={{ width: '18%' }}>{}</StyledTableCell>
                      {notificationDetails?.screenName.includes('Bill Only-Order') && (
                        <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
                      )}
                      <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
                      <StyledTableCell style={{ width: '7%' }}>{}</StyledTableCell>
                      <StyledTableCell style={{ width: '8%' }}>{}</StyledTableCell>
                      <StyledTableCell style={{ width: '8%' }}>{}</StyledTableCell>
                      <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
                      <StyledTableCell style={{ width: '8%', textAlign: 'center' }}>
                        {shipping.deliveryQuantity ? Number(shipping.deliveryQuantity) : ''}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '7%', textAlign: 'center' }}>
                        {shipping.erpOrderNumber}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '6%', whiteSpace: 'nowrap', textAlign: 'center' }}
                      >
                        {shipping?.trackingNumber && shipping?.trackingNumber !== 'N/A' ? (
                          <>
                            {shipping?.shipmentSource === 'FEDEX' && (
                              <a
                                rel="noreferrer noopener"
                                target="_blank"
                                href={`https://www.fedex.com/fedextrack/?trknbr=${shipping?.trackingNumber}`}
                              >
                                {shipping?.trackingNumber}
                              </a>
                            )}
                            {shipping?.shipmentSource === 'UPS' && (
                              <a
                                rel="noreferrer noopener"
                                target="_blank"
                                href={`https://www.ups.com/track?loc=en_US&tracknum=${shipping?.trackingNumber}`}
                              >
                                {shipping?.trackingNumber}
                              </a>
                            )}
                            {shipping?.shipmentSource !== 'FEDEX' &&
                              shipping?.shipmentSource !== 'UPS' &&
                              shipping?.trackingNumber}
                          </>
                        ) : (
                          ''
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '6%', textAlign: 'center' }}>
                        {shipping?.deliveredDate}
                      </StyledTableCell>
                    </StyledDataTableRow>
                  );
                })}
              </Table>
            </Box>
          </Collapse>
        </StyledCollapsibleTableCell>
      </TableRow>
    </>
  );
};

export default withRouter(NotificationDetails);
