/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_USER_TERRITORIES = gql`
  query getUserTerritories($userId: String, $filters: UserSubInventoryFilters) {
    getUserTerritories(userId: $userId, filters: $filters) {
      id
      value
      subInventoryCode
      ithasinventory
      __typename
    }
  }
`;
